import React, { useState, useEffect } from 'react'
import axios from 'axios'

import './App.css'

declare var FB

const MAX_POSTS = 3
const hashtagId = '17841561274072479'
const userId = '17841416732372122'

const params = new URLSearchParams(window.location.search)
const useScraper = params.get('use-scraper') === 'true'

const loadIGPosts = async (recent = true, maxPosts = MAX_POSTS) => {
  try {
    let rawPosts = []

    // FB API
    if (!useScraper)
      rawPosts = await new Promise((resolve, reject) => {
        FB.api(
          `/${hashtagId}/${recent ? 'recent_media' : 'top_media'}`,
          'GET',
          { user_id: userId, fields: 'permalink,children{permalink}', limit: maxPosts },
          response => {
            if (response.error) reject(response.error)
            resolve(response.data)
          },
        )
      })

    // Scraper
    if (useScraper) {
      const response = await axios({ method: 'get', url: 'http://localhost:4000' })
      rawPosts = response.data
    }

    const igPosts = await Promise.all(
      rawPosts.slice(0, MAX_POSTS).map(post =>
        post.children
          ? Promise.all([
              axios({
                method: 'get',
                url: 'https://api.instagram.com/oembed/?url=' + post.children.data[0].permalink,
              }),
              axios({
                method: 'get',
                url: 'https://api.instagram.com/oembed/?url=' + post.permalink,
              }),
            ]).then(([imageResponse, carouselResponse]) => ({
              ...imageResponse,
              data: { ...imageResponse.data, title: carouselResponse.data.title },
            }))
          : axios({
              method: 'get',
              url: 'https://api.instagram.com/oembed/?url=' + post.permalink,
            }),
      ),
    )

    return igPosts.map(post => post.data)
  } catch (error) {
    throw error
  }
}

const getHashtags = (input = '') => {
  const regex = /(#| @)[A-Za-z0-9\-\.\_]+/g
  const matches = []

  let match = []
  while ((match = regex.exec(input)) !== null) {
    matches.push({ text: match[0], index: match.index })
  }

  return matches.reduce(
    (acc, cur, i) => {
      return [
        ...acc,
        { text: cur.text, hashtag: true },
        { text: input.slice(cur.index + cur.text.length, matches[i + 1] ? matches[i + 1].index : input.length), hashtag: false },
      ]
    },
    [{ text: input.slice(0, matches[0].index), hashtag: false }],
  )
}

const App = () => {
  const [initialLoad, setInitialLoad] = useState(false)
  const [loggedIn, setLogin] = useState(false)
  const [posts, setPosts] = useState([])

  const loadPosts = () => {
    if (useScraper)
      return loadIGPosts().then(posts => {
        if (posts) setPosts(posts)
      })

    if (loggedIn)
      return loadIGPosts().then(newPosts => {
        if (newPosts.length === MAX_POSTS) setPosts(newPosts)
        if (newPosts.length < MAX_POSTS)
          return loadIGPosts(false, MAX_POSTS - newPosts.length).then(topPosts => setPosts([...newPosts, ...topPosts]))
      })

    return Promise.resolve()
  }

  const login = () => {
    FB.login(
      response => {
        if (response.authResponse) {
          setLogin(true)
        } else {
          console.log('User cancelled login or did not fully authorize.')
        }
      },
      { scope: 'pages_show_list,instagram_basic' },
    )
  }

  useEffect(() => {
    if (!useScraper) loadPosts()
  }, [loggedIn])

  useEffect(() => {
    if (!initialLoad) loadPosts().then(() => setInitialLoad(true), () => setInitialLoad(true))

    const interval = setInterval(
      () => {
        console.log('Reload')
        loadPosts().catch(() => clearInterval(interval))
      },
      useScraper ? 5000 : 30000,
    )

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <div className="wrapper">
      {!useScraper && !loggedIn && <button onClick={() => login()}>Login</button>}

      <div className="title-wrapper">
        <img src="/kreative-kraefte-logo-weiss.svg" alt=""></img>
        <img src="/formkraft-allgaeu_logo_white.svg" alt=""></img>
        <h1>K4+1-Nacht in Leutkirch am 9. August 2019</h1>
      </div>

      <div className="post-wrapper">
        {posts &&
          posts.map(post => <Post key={post.media_id} thumbnail={post.thumbnail_url} title={post.title} author={post.author_name} />)}
      </div>
    </div>
  )
}

const Post = ({ thumbnail, title, author }) => (
  <div className="post">
    <div className="image-wrapper">
      <img src={thumbnail} alt={title}></img>
    </div>

    <p>
      <strong>{author}</strong>
      &nbsp;
      {getHashtags(title).map((snippet, i) =>
        snippet.hashtag ? (
          <span key={i} className="hashtag">
            {snippet.text}
          </span>
        ) : (
          <span key={i}>{snippet.text}</span>
        ),
      )}
    </p>
  </div>
)

export default App
